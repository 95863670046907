import { FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../../../componenets/TextFieldInput";
import { CarOilChange } from "../../../../../types/common.types";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import FileUpload from "../../../../../componenets/FileUpload";
import DatePickerInput from "../../../../../componenets/DatePickerInput";
import { useAddCarOilChange } from "./hooks/useAddCarOilChange";
import CheckboxInput from "../../../../../componenets/CheckboxInput";

interface Props {
  carId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    at_distance: yup.number().min(0).required(),
    amount: yup.number().min(0).required(),
    date: yup.date().required(),
    do_not_deduct: yup.boolean().required(),
  })
  .required();

export default function AddCarOilChangeModal({
  carId,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { control, reset, handleSubmit } = useForm<CarOilChange>({
    defaultValues: {
      at_distance: 0,
      amount: 0,
      date: new Date(),
      do_not_deduct: false,
    },
    resolver: yupResolver(schema),
  });
  const addCarOilChange = useAddCarOilChange(carId);

  const handleClose = () => {
    reset({
      at_distance: 0,
      amount: 0,
      date: new Date(),
      do_not_deduct: false,
    });
    setFile(null);

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (oilChange: CarOilChange) => {
    setLoading(true);

    try {
      await addCarOilChange({ oilChange, invoice: file });

      await queryClient.invalidateQueries([
        queryKeysConstants.cars,
        carId,
        "oil-changes",
      ]);

      handleClose();

      onShowToast(t("oil_change_saved"), false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      title={t("add_oil_change")}
      onClose={handleClose}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            name="at_distance"
            label={t("at_distance")}
            type="number"
          />
          <TextFieldInput
            control={control}
            name="amount"
            label={t("amount")}
            type="number"
          />
          <DatePickerInput control={control} name="date" label={t("date")} />
          <CheckboxInput
            control={control}
            name="do_not_deduct"
            label={t("do_not_deduct")}
          />
          <FileUpload label={t("invoice")} file={file} setFile={setFile} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
